<template>
  <div class="component-wrapper">
    <b-card class="main-content">
      <template #header>
        <create-button text="Add Administrator" class="ml-auto" @click.stop="showDetail(null)"></create-button>
      </template>
      <template #default>
        <vue-element-loading :active="loader" />
        <b-table
          :items="table.items"
          :fields="table.fields"
          sort-by="email"
          stacked="sm"
          small
          fixed
          hover
          striped
          show-empty
          :per-page="table.paging.pageSize"
          :current-page="table.paging.pageNo"
          :filter="table.filter"
        >
          <template #cell(email)="data">
            <span>{{ data.item.email }}</span>
          </template>
          <template #cell(action)="data">
            <div v-if="data.item.userCode !== user.userCode">
              <b-button title="Reset Password" variant="info" size="sm" @click.stop="resetPassword(data.item.userCode)">
                <feather-icon icon="KeyIcon"></feather-icon>
              </b-button>
              <edit-button class="ml-1" @click.stop="showDetail(data.item.userCode)"></edit-button>
              <delete-button
                class="ml-1"
                :content="data.item.isActive ? `Deactivate` : `Activate`"
                :variant="data.item.isActive ? `danger` : `success`"
                icon="PowerIcon"
                @click.stop="toggleActive(data.item)"
              >
              </delete-button>
              <delete-button v-if="!data.item.isActive" class="ml-1"></delete-button>
            </div>
            <div v-else>
              <edit-button class="ml-1" @click.stop="showDetail(data.item.userCode)"></edit-button>
            </div>
          </template>
        </b-table>
      </template>

      <template #footer>
        <div class="d-flex mt-auto align-items-center">
          <page-info :page-no="table.paging.pageNo" :page-size="table.paging.pageSize" :total="table.items.length" />
          <b-pagination
            v-model="table.paging.pageNo"
            :totalRows="table.items.length"
            :per-page="table.paging.pageSize"
            align="center"
            class="ml-auto"
          ></b-pagination>
        </div>
      </template>
    </b-card>
    <b-modal
      v-model="modal"
      :title="!table.selected ? `Add Administrator` : `Edit Administrator`"
      hide-footer
      centered
      no-close-on-backdrop
      size="lg"
      lazy
      @hide="table.selected = null"
    >
      <detail :userCode="table.selected" @onSaveSuccess="saveSuccess" @onCancel="cancel" />
    </b-modal>
  </div>
</template>

<script>
import api from "@api";
import Detail from "./component/Detail.vue";
import { mapState } from "vuex";

export default {
  name: `UserAccountList`,
  components: { Detail },
  data() {
    return {
      table: {
        items: [],
        fields: [
          { key: `email`, label: `Email`, sortable: true },
          { key: `action`, label: `Action`, class: `text-right` },
        ],
        paging: {
          pageNo: 1,
          pageSize: 20,
        },
        selected: null,
      },
      loader: false,
      modal: false,
    };
  },
  computed: {
    ...mapState(`auth`, [`user`]),
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    showDetail(userCode) {
      this.table.selected = userCode;
      this.modal = true;
    },
    async loadData() {
      this.loader = true;

      try {
        this.table.items = await api.get(`useraccount/admin`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },

    async saveSuccess() {
      this.showToast(`success`, `User Account save success`);
      this.modal = false;
      this.loadData();
    },

    async resetPassword(userCode) {
      let confirmed = await this.showConfirm(`Reset Password ?`);
      if (!confirmed) return;
      this.loader = true;
      try {
        await api.put(`useraccount/reset-password/${userCode}`);
        this.showToast(`success`, `Password has been reset`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },

    async toggleActive(rec) {
      let confirmed = await this.showConfirm(`Toggle Active ?`);
      if (!confirmed) return;
      this.loader = true;
      try {
        await api.delete(`useraccount/${rec.userCode}`);
        rec.isActive = !rec.isActive;
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },

    cancel() {
      this.modal = false;
    },
  },
};
</script>
