<template>
  <b-form @submit.prevent="save">
    <vue-element-loading :active="loader" />
    <b-row>
      <b-col>
        <b-form-group label="Email">
          <b-form-input v-model="form.email" trim />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Telegram Chat Id">
          <b-form-input v-model="form.telegramChatId" trim />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="text-right">
      <submit-button :disabled="loader.form" class="ml-auto mr-1" />
      <cancel-button :disabled="loader.form" variant="info" @click.stop="cancel" />
    </div>
  </b-form>
</template>

<script>
import api from "@api";

export default {
  name: `UserAccountDetail`,
  props: {
    userCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        email: null,
        telegramChatId: null,
        roleCode: "ADM",
      },
      loader: false,
    };
  },
  mounted() {
    if (this.userCode) {
      this.loadUser();
    }
  },

  methods: {
    async loadUser() {
      this.loader = true;

      try {
        this.form = await api.get(`useraccount/${this.userCode}`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },

    async save() {
      this.loader = true;
      try {
        let mode = this.userCode ? "update" : "create";

        if (mode == `update`) {
          await api.put(`useraccount/${this.userCode}`, this.form);
        } else {
          await api.post("useraccount", this.form);
        }
        this.$emit("onSaveSuccess");
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },

    cancel() {
      this.$emit("onCancel");
    },
  },
};
</script>
